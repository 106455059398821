body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
img{
  width: 100%;
}
form {
  width: 350px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  form h5 {
    text-align: center;
    margin-bottom: 25px; }
  form input, form button {
    width: 100%;
    text-align: center; }

select {
  padding: 10px;
  width: 80%;
  display: block;
  height: 45px;
  margin: 25px auto; }
  select option {
    padding: 0px 5px;
    text-align: center; }

.dashboardContainer {
  min-height: 20vh;
  margin-top: 60px; }
  .dashboardContainer .card-item {
    height: 143px;
    width: 255px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 6px;
    margin: 1rem;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.14), 0 4px 4px rgba(0, 0, 0, 0.14);
    position: relative; }
    .dashboardContainer .card-item .card-content {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .dashboardContainer .card-item .ic {
      margin-top: 21px; }
    .dashboardContainer .card-item .title {
      color: #4f4f4f;
      margin-top: 5px; }
    .dashboardContainer .card-item p {
      text-transform: capitalize;
      font-size: 18px; }
    .dashboardContainer .card-item .a {
      color: #50ae54; }


