form{
    width: 350px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    h5{
        text-align: center;
        margin-bottom: 25px;
    }
    input,button{
        width: 100%;
        text-align: center;
    }
}