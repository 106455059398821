select{
    padding: 10px;
    width: 80%;
    display: block;

    height: 45px;
    margin: 25px auto; 
    option{
        padding: 0px 5px;
        text-align: center
    }
}