.dashboardContainer{
    min-height: 20vh;
    margin-top: 60px;
    .card-item {
        height: 143px;
        width: 255px;
        text-align: center;
        background-color: #ffffff;
        border-radius: 6px;
        margin: 1rem;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.14), 0 4px 4px rgba(0, 0, 0, 0.14);
        position: relative;
        .card-content{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }
        .ic {
            margin-top: 21px;
        }
        .title {
            color: #4f4f4f;
            margin-top: 5px;
            
        }  
        p{
            text-transform: capitalize;
            font-size: 18px;
        }
        .a {
            color: #50ae54;
        }
    }
      
}